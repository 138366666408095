import React from "react"
import CookieConsent, { OPTIONS } from "react-cookie-consent"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import { useLinksDictionary } from "../dictionaries/linksDictionary"

const Cookies = () => {
  const intl = useIntl()
  const { links } = useLinksDictionary()
  return (
    <CookieConsent
      disableStyles={true}
      location={OPTIONS.BOTTOM}
      buttonClasses="cookie-btn"
      containerClasses="cookie-container"
      contentClasses="cookie-content"
      cookieName="CarebitsCookiesConsent"
      buttonText={intl.formatMessage({
        id: "cookie_btnAccept",
        defaultMessage: "Akceptuję",
      })}
    >
      <FormattedMessage
        id="cookie_Message"
        defaultMessage="Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym
      poziomie. Dalsze korzystanie oznacza, że akceptujesz naszą "
      />
      <a target="_blank" href={links.privacy_policy}>
        <FormattedMessage
          id="cookie_PolicyLink"
          defaultMessage="politykę prywatności"
        />
      </a>
    </CookieConsent>
  )
}

export default Cookies
