import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Drawer from "@material-ui/core/Drawer"

import logo from "../assets/logo.svg"
import { List } from "react-bootstrap-icons"

const CustomNavbar = () => {
  const { strapiNav } = useStaticQuery(
    graphql`
      query {
        strapiNav {
          our_team {
            title
            link
          }
          how_it_works {
            title
            link
          }
          faq {
            title
            link
          }
          contact {
            title
            link
          }
          login {
            title
            link
          }
          order {
            title
            link
          }
        }
      }
    `
  )

  const [toggledMenu, setToggleMenu] = useState(false)

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setToggleMenu(open)
  }

  const menuItems = (
    <>
      <Link to={strapiNav.how_it_works.link} className="nav-btn">
        {strapiNav.how_it_works.title}
      </Link>
      <Link to={strapiNav.our_team.link} className="nav-btn">
        {strapiNav.our_team.title}
      </Link>
      <Link to={strapiNav.faq.link} className="nav-btn">
        {strapiNav.faq.title}
      </Link>
      <Link to={strapiNav.contact.link} className="nav-btn">
        {strapiNav.contact.title}
      </Link>
      <Link to={strapiNav.login.link} className="nav-btn">
        {strapiNav.login.title}
      </Link>
      <Link to={strapiNav.order.link} className="order-nav-btn">
        {strapiNav.order.title}
      </Link>
    </>
  )

  return (
    <>
      <Box
        component={Container}
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        id="site-navbar"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          className="nav-content"
        >
          <Link to="/" className="">
            <img src={logo} width="200px" alt="Carebits logo"></img>
          </Link>
          <Box className="mui-fixed" display={{ xs: "none", md: "contents" }}>
            {menuItems}
          </Box>
          <Box display={{ xs: "contents", md: "none" }}>
            <Button className="navbar-toggler" onClick={toggleDrawer(true)}>
              <List></List>
            </Button>
            <Drawer
              anchor={"top"}
              open={toggledMenu}
              onClose={toggleDrawer(false)}
            >
              <Box
                className="nav-content-drawer"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                {menuItems}
              </Box>
            </Drawer>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default CustomNavbar
